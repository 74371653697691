<template>
    <v-row justify="center" class="text-caption">
      <v-dialog
        class="rounded-t-xl"
        v-model="dialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar  class="rounded-t-xl" color="#05445E">
            <template v-slot:image>
              <v-img
                          src="./fond_vague_long.png"
                          class="align-end"
                          gradient="to right, #05445EDD, #05445EFF"
                          cover
                        ></v-img></template>
            <v-btn icon color="white" @click="dialog = false">
              <v-icon>mdi:mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="color:white">Dictionnaire Commun</v-toolbar-title>
         
          </v-toolbar>
          <v-form v-model="valid">
            <v-container>

                    <v-row>
                      <v-col cols="4" md="4" class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.outil"
                          label="Outil"
                          readonly
                          hide-details
                        ></v-text-field>
                      </v-col>
  
                      <v-col cols="4" md="4" class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.itemLabel"
                          label="Élément"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4"  class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.nombre"
                          label="Nombre"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                
                    </v-row>
  
  
                    <v-row>
                      <v-col cols="12" class="pa-1">
                        <QuillEditor  v-if="adherent.role=='Administrateur'"  theme="snow"  contentType="html"
                      
                        :options="editorOption"      label="commentaire"  v-model:content="dictionnaire.content"/>
                        <div v-else> <p   class="content ql-editor" v-html="dictionnaire.content"> </p></div>
                      </v-col>
                    </v-row>
  
                         

            </v-container>
          </v-form>
          <br/>
          <br/>
          <br/>
          <v-card-actions>
  <v-btn prepend-icon="mdi:mdi-content-save"    color="#05445E"  variant="flat"  class="text-white " 
 @click="save" v-if="adherent.role=='Administrateur'" 
 >
 Enregistrer
 </v-btn>
 </v-card-actions>
        </v-card>
      </v-dialog>
   
 
    
    </v-row>
  </template>
  
  <script>
  import dictionnaireCommunService from "../services/dictionnaireCommunService.js";
  import { store } from '../store.js'
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import configService from '../services/configService';
  export default {
    components: {
    QuillEditor
  },
    data() {
      return {
        adherent:null,
        dialog: false,
        menu2: false,
        valid: false,
        dictionnaire: { outil: "", id: "", item: "", itemLabel: "", content: "", },
        editorOption: configService.getQuillConfig(),
      };
    },
    expose: ["open"],
    emits: ["updatedDictionnaires"],
    methods: {


      save() {
        if (this.valid) {
            dictionnaireCommunService
            .save(this.dictionnaire)
            .then(() => {
              this.dialog = false;
              this.$emit("updatedDictionnaires");
            })
            .catch((e) => {
              console.log("serveur KO");
              console.log(e);
            });
        }
      },
  



      open(comId) {
   
        this.adherent = store.user
        dictionnaireCommunService
          .get(comId)
          .then((resutlt) => {
            this.dictionnaire = resutlt.data;
            this.dialog = true;
       
          })
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);
          });
    
      },
    },
  };
  </script>
  
  <style>
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
  }
  </style>
  