
<template>
    <v-container>
   <div id="cycle9ans" style="position: relative;top:10px; height: 650px;width: 750px;background-color: transparent;">  
    <div style="position: absolute; top: 50px; left: 30px ; width: 270px; height: 100px;color: #FF0000;">
        <img  v-on:click="showComModal('Année '+cycle9ans[0].valeur)"  :src="getImage(cycle9ans[0])" height="50" style="float: right">
        <div style="width:75px;float:right;margin-right:5px;height: 100px;">
          <p align="right" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
            <span :class="[ showCom?'linklookalike':'',cycle9ans[0].couleur ]" v-on:click="showComModal('Année '+cycle9ans[0].valeur)" >{{ cycle9ans[0].detail}}<br> {{      cycle9ans[0].valeur }}</span>
          </p>
        </div>
    
    </div>
    <div  v-on:click="showComModal('Année '+cycle9ans[0].valeur)"  style="position: absolute; top: 40px; left: -75px ; width: 250px; height: 100px;color: #FF0000;">
      <p align="right" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">
        RENOUVEAU<br/>
        AFFIRMATION<br/>
        ACTION   </p>
  </div>
    <div style="position: absolute; top: 50px; left: 700px ; width: 270px; height: 100px;color: #F19300;">
      <img  v-on:click="showComModal('Année '+cycle9ans[8].valeur)"  :src="getImage(cycle9ans[8])" height="50" style="float: left">
      <div style="width:75px;float:left;margin-left:5px;height: 100px;">
        <p align="left" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
          <span :class="[ showCom?'linklookalike':'',cycle9ans[8].couleur ]" v-on:click="showComModal('Année '+cycle9ans[8].valeur)"  >{{ cycle9ans[8].detail}}<br> {{      cycle9ans[8].valeur  }}</span>
          </p>
      </div>
    </div>
    <div  v-on:click="showComModal('Année '+cycle9ans[8].valeur)"  style="position: absolute; top: 40px; left: 820px ; width: 250px; height: 100px;color: #F19300;">
    <p align="Left" style="margin:0;padding:0; font-size: 17px;  line-height:0.9">
      PREPARATIONS<br/>
      CONSCIENCE ELARGIE<br/>
      RECOLTES<br/>
      GRATITUDE </p>
</div>

    <div style="position: absolute; top: 160px; left: 80px ; width: 270px; height: 100px;color: #0072A0;">
      <img :src="getImage(cycle9ans[1])" height="50" style="float: right"  v-on:click="showComModal('Année '+cycle9ans[1].valeur)">
      <div style="width:75px;float:right;margin-right:5px;height: 100px;">
        <p align="left" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
          <span :class="[ showCom?'linklookalike':'',cycle9ans[1].couleur ]" v-on:click="showComModal('Année '+cycle9ans[1].valeur)"  >{{ cycle9ans[1].detail}}<br> {{      cycle9ans[1].valeur  }}</span>  
      </p>
      </div>
    </div>
    <div  v-on:click="showComModal('Année '+cycle9ans[1].valeur)" style="position: absolute; top: 160px; left: -20px ; width: 250px; height: 100px;color: #0072A0;">
      <p align="right" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">  
        RELATION A SOI<br/>
        RELATION AUX AUTRES<br/>
        SERENITE    </p>
  </div>
    <div style="position: absolute; top: 160px; left: 650px ; width: 270px; height: 100px;color: #00662C;">
      <img v-on:click="showComModal('Année '+cycle9ans[7].valeur)"  :src="getImage(cycle9ans[7])" height="50" style="float: left">
      <div style="width:75px;float:left;margin-left:5px;height: 100px;">
        <p align="right" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
          <span :class="[ showCom?'linklookalike':'',cycle9ans[7].couleur ]" v-on:click="showComModal('Année '+cycle9ans[7].valeur)"  >{{ cycle9ans[7].detail}}<br> {{      cycle9ans[7].valeur  }}</span>  
         </p>
      </div>
    </div>
    <div v-on:click="showComModal('Année '+cycle9ans[7].valeur)"  style="position: absolute; top: 160px; left: 790px ; width: 250px; height: 100px;color: #00662C;">
      <p align="left" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">  
        TRANSFORMATIONS<br/>
        PUISSANCE<br/>
        RECOLTES    </p>
  </div>
    <div style="position: absolute; top: 270px; left: 130px ; width: 270px; height: 100px;color: #FFEC00;">
      <img  v-on:click="showComModal('Année '+cycle9ans[2].valeur)"  :src="getImage(cycle9ans[2])" height="50" style="float: right">
      <div style="width:75px;float:right;margin-right:5px;height: 100px;">
        <p align="left" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
          <span :class="[ showCom?'linklookalike':'',cycle9ans[2].couleur ]" v-on:click="showComModal('Année '+cycle9ans[2].valeur)"  >{{ cycle9ans[2].detail}}<br> {{      cycle9ans[2].valeur  }}</span> 
        </p>
    </div>
  </div>
    <div  v-on:click="showComModal('Année '+cycle9ans[2].valeur)"  style="position: absolute; top: 270px; left: 0px ; width: 250px; height: 100px;color: #FFEC00;">
      <p align="right" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">  
        EXPRESSION<br/>
        CREATIVITE<br/>
        JOIE    </p>
  </div>
  
  <div style="position: absolute; top: 270px; left: 600px ; width: 270px; height: 100px;color: #6E3E91;">
    <img v-on:click="showComModal('Année '+cycle9ans[6].valeur)"  :src="getImage(cycle9ans[6])" height="50" style="float: left">
    <div style="width:75px;float:left;margin-left:5px;height: 100px;">
      <p align="right" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
        <span :class="[  showCom?'linklookalike':'',cycle9ans[6].couleur ]" v-on:click="showComModal('Année '+cycle9ans[6].valeur)"  >{{ cycle9ans[6].detail}}<br> {{      cycle9ans[6].valeur }}</span> 
                 </p>
    </div>
  </div>

  <div  v-on:click="showComModal('Année '+cycle9ans[6].valeur)"  style="position: absolute; top: 270px; left: 725px ; width: 250px; height: 100px;color: #6E3E91;">
    <p align="left" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">  
      REFLEXIONS<br/>
      PREPARATIONS<br/>
      PERFECTIONNEMENTS    </p>
  </div>

    <div style="position: absolute; top: 380px; left: 180px ; width: 270px; height: 100px;color: #834500;">
    <img  v-on:click="showComModal('Année '+cycle9ans[3].valeur)"   :src="getImage(cycle9ans[3])" height="50" style="float: right">
    <div style="width:75px;float:right;margin-right:5px;height: 100px;">
      <p align="left" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
        <span :class="[ showCom?'linklookalike':'',cycle9ans[3].couleur ]" v-on:click="showComModal('Année '+cycle9ans[3].valeur)"  >{{ cycle9ans[3].detail}}<br> {{      cycle9ans[3].valeur }}</span> 
      </p>
    </div>
    </div>

    <div  v-on:click="showComModal('Année '+cycle9ans[3].valeur)"   style="position: absolute; top: 380px; left: 60px ; width: 250px; height: 100px;color: #834500;">
      <p align="right" style="margin:0;padding:0; font-size: 17px;   line-height:0.9" >   
        CONSTRUCTION<Br/>
        RACINES<Br/>
        PATIENCE<Br/>
        SECURITE  </p>
  </div>


  <div style="position: absolute; top: 380px; left: 550px ; width: 270px; height: 100px;color: #E73194;" >
    <img  v-on:click="showComModal('Année '+cycle9ans[5].valeur)"   :src="getImage(cycle9ans[5])" height="50" style="float: left">
    <div style="width:75px;float:left;margin-left:5px;height: 100px;">
      <p align="right" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
        <span :class="[ showCom?'linklookalike':'',cycle9ans[5].couleur ]" v-on:click="showComModal('Année '+cycle9ans[5].valeur)"  >{{ cycle9ans[5].detail}}<br> {{      cycle9ans[5].valeur  }}</span>                
       </p>
    </div>
  </div>

  <div  v-on:click="showComModal('Année '+cycle9ans[5].valeur)"   style="position: absolute; top: 380px; left: 690px ; width: 250px; height: 100px;color: #E73194;">
    <p align="left" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">   
      ENGAGEMENTS Personnels et Professionnels<Br/>
      AMOUR </p>
  </div>


      <div style="position: absolute; top: 490px; left: 365px ; width: 270px; height: 100px;color: #68BD0D;">
      <p align="center" style="margin:0;padding:0">
      <img v-on:click="showComModal('Année '+cycle9ans[4].valeur)"   :src="getImage(cycle9ans[4])" height="50" align="center">
    </p>
      <p align="center" style="margin:0;padding:0; font-size: 20px; line-height: 1;">
        <span :class="[ showCom?'linklookalike':'',cycle9ans[4].couleur ]" v-on:click="showComModal('Année '+cycle9ans[4].valeur)"  >{{ cycle9ans[4].detail}}<br> {{      cycle9ans[4].valeur }}</span>
       </p>
    </div>
    <div  v-on:click="showComModal('Année '+cycle9ans[4].valeur)"   style="position: absolute; top: 600px; left: 375px ; width: 250px; height: 100px;color: #68BD0D;">
      <p align="center" style="margin:0;padding:0; font-size: 17px;   line-height:0.9">  MODIFICATIONS<br/>
        EVOLUTION<br/>
        LIBERTE <br/>
        PLAISIR  </p>
  </div>

</div>

   
<CommentaireSaisieComponent :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:updated='this.$emit("commentaireUpdated")' v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

    </v-container>
    </template>

    <script setup>

</script>
    <script>
 import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
  import clientService from "../services/clientService";
      export default {
        components: {CommentaireSaisieComponent},
        props: ['dateetude','showCom','etudeId','clientId','commentaires'],
 
  computed:{
    filterItems() {  return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil):[] },},
  watch: {
    clientId(val) {
      if(val)
      this.refresh();
    },
    dateetude(val) {
      if(val)
      this.refresh();
    },
  },
   mounted() {
   this.refresh()

   
          },
        data () {
          return {
            cycle9ans: [{detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},
            {detail:"",couleur:"", valeur:""},],
            anneePersonnelle:"",
            jourPersonnel:"",
            moisPersonnel:"",
            agendaMoisJour:[],
            agendaMois:[],
            moisCycle:[],
            exemple:"exemple",
            search: '',
            showCommentaireSaisieComponent: false,
      outil:"Cycle de 9 ans",
      item:"Année 1",
      crtValue:"1",
          }
        },
        methods: {
        
          showComModal(itx){
        
      if(this.showCom){
      this.item = itx;
      this.crtValue = "";
      this.showCommentaireSaisieComponent = this.showCom;
    }  },
            getImage(annee)
          {
            if(annee)
             return "/cycle9ans"+annee.couleur+".png"  
            else return ""
           },
          formatedDate(unedate)
          {
            if(unedate) return (new Date(unedate)).toISOString().substring(0,10)
              else return ""
          },
        refresh() {
          clientService.getCycleNeuf(this.clientId, this.dateetude+"-01-01").then((resutlt) => {
              this.cycle9ans = resutlt.data
           
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    
 
        },
      }
      }
    </script>